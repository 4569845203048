<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'"> {{ $t("message.dealGoals") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :className="'w100'"
                        :class="mode ? 'input__day' : 'input__night'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th v-if="columns.year.show">
                    {{ columns.year.title }}
                </th>
                <th v-if="columns.filial_id.show">
                    {{ columns.filial_id.title }}
                </th>
                <th v-if="columns.count.show">
                    {{ columns.count.title }}
                </th> 
                <th v-if="columns.money_amount.show">
                    {{ columns.money_amount.title }}
                </th> 
                <th v-if="columns.profit.show">
                    {{ columns.profit.title }}
                </th>
                <th v-if="columns.currency_id.show">
                    {{ columns.currency_id.title }}
                </th>  
                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>
            </tr>

            <tr class="filter_sorche">

              <th v-if="columns.year.show">
                  <crm-input
                      :placeholder="columns.year.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.year"
                  ></crm-input>
              </th>

              <th v-if="columns.filial_id.show">
                  <crm-input
                      :placeholder="columns.filial_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.filial_id"
                  ></crm-input>
              </th>

              <th v-if="columns.count.show">
                  <crm-input
                      :placeholder="columns.count.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.count"
                  ></crm-input>
              </th>

              <th v-if="columns.money_amount.show">
                  <crm-input
                      :placeholder="columns.money_amount.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.money_amount"
                  ></crm-input>
              </th>

              <th v-if="columns.profit.show">
                  <crm-input
                      :placeholder="columns.profit.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.profit"
                  ></crm-input>
              </th>

               <th v-if="columns.currency_id.show">
                  <crm-input
                      :placeholder="columns.currency_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.currency_id"
                  ></crm-input>
              </th>
              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="(dealGoal, index) in list" :key="index" class="cursor-pointer">

                <td v-if="columns.year.show">
                    {{ dealGoal.year }}
                </td>
                
                <td v-if="columns.filial_id.show">
                    {{ dealGoal.filial ? dealGoal.filial.name : ''}}
                </td>

                <td v-if="columns.count.show">
                    {{ dealGoal.count }}
                </td>

                <td v-if="columns.money_amount.show">
                    {{ dealGoal.money_amount }}
                </td>

                <td v-if="columns.profit.show">
                    {{ dealGoal.profit }}
                </td>

                <td v-if="columns.currency_id.show">
                    {{ dealGoal.currency ? dealGoal.currency.symbol : ''}}
                </td>
                <td v-if="columns.settings.show" class="settings-td">
                     <crm-settings
                        :name="$options.name"
                        :model="dealGoal"
                        :actions="actions"
                        :permissionShow="'dealGoals.update'"
                        :permissionDestroy="'dealGoals.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg body_scroll">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               size="70%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "dealGoals",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "dealGoals/list",
            columns: "dealGoals/columns",
            pagination: "dealGoals/pagination",            
            filter: "dealGoals/filter",
            sort: "dealGoals/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "dealGoals/dealGoalsIndex",
            setPagination: "dealGoals/setPagination",
            updateSort: "dealGoals/updateSort",
            updateFilter: "dealGoals/updateFilter",
            updateColumn: "dealGoals/updateColumn",
            updatePagination: "dealGoals/updatePagination",
            show: "dealGoals/show",
            empty: "dealGoals/empty",
            delete: "dealGoals/destroy",
            refreshData: "dealGoals/refreshData",
        }),
        destroy(model) {          
          this.delete({year: model.year, filial_id: model.filial_id})
              .then(res => {
                  this.$alert(res);
                  this.fetchData()
              })
              .catch(err => {
                this.$alert(err);
              })
      },
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('dealGoals/EMPTY_LIST');
        next()
    },
    
};
</script>

